import { RootState, _ } from "common";
import { createSelector } from "reselect";

const selectProduct = (state: RootState) => state.Products;

const selectHomeProducts = createSelector(
  selectProduct,
  (state) => state.homeProducts
);
const selectProductsHomePaginationMeta = createSelector(
  selectProduct,
  (state) => state.productsHomePaginationMeta
);

const selectHomeProductsLoading = createSelector(
  selectProduct,
  (state) => state.productsHomePageLoading
);

const selectHomeProductsNextPageLoading = createSelector(
  selectProduct,
  (state) => state.productsHomePageNextPageLoading
);

const selectHomeProductsRequest = createSelector(
  selectProduct,
  (state) => state.getHomeProductsRequest
);

const selectProductsSearchBarItems = createSelector(
  selectProduct,
  (state) => state.productsSearchBarItems
);

const selectProductsSearchBarLoading = createSelector(
  selectProduct,
  (state) => state.productsSearchBarLoading
);

const selectLatestProductsItems = createSelector(
  selectProduct,
  (state) => state.latestProductsItems
);

const selectLatestProductsLoading = createSelector(
  selectProduct,
  (state) => state.latestProductsLoading
);

const selectSearchProductsItems = createSelector(
  selectProduct,
  (state) => state.searchProductsItems
);

const selectSearchProductsResponse = createSelector(
  selectProduct,
  (state) => state.searchProductsResponse
);

const selectProductSearchingFacet = createSelector(
  selectProduct,
  (state) => state.searchFacet
);

const selectSearchProductsLoading = createSelector(
  selectProduct,
  (state) => state.searchProductsLoading
);

const selectBestSellingLoading = createSelector(
  selectProduct,
  (state) => state.bestSellingProductsLoading
);

const selectBestSellingProducts = createSelector(
  selectProduct,
  (state) => state.bestSellingProducts
);

const selectAlternativeProductsLoading = createSelector(
  selectProduct,
  (state) => state.isAlternativeProductsLoading
);

const selectAlternativeProducts = createSelector(
  selectProduct,
  (state) => state.alternativeProductsData
);

export const selectors = {
  selectProduct,

  selectProductsHomePaginationMeta,
  selectHomeProducts,
  selectHomeProductsRequest,
  selectHomeProductsLoading,
  selectHomeProductsNextPageLoading,

  selectProductsSearchBarItems,
  selectProductsSearchBarLoading,
  selectProductSearchingFacet,

  selectLatestProductsItems,
  selectLatestProductsLoading,

  selectSearchProductsItems,
  selectSearchProductsLoading,
  selectSearchProductsResponse,

  selectBestSellingLoading,
  selectBestSellingProducts,
  selectAlternativeProductsLoading,
  selectAlternativeProducts,
};
