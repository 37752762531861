import { createSelector } from "reselect";
import { RootState, SliceStatusValue, _ } from "common";
import { CPU, MEMORY, Motherboard, MOTHERBOARD, RAM } from "./constants";
import { ComponentName } from "./types";
import {
  mapCategoryNameToComponentName,
  mapComponentNameToCategoryName,
  getTotalViaQtyAndRetailPrice,
} from "./utils";

const selectPCBuilder = (state: RootState) => state.PCBuilder;

const selectPartsPrimaryCategory = createSelector(
  selectPCBuilder,
  (state) => state.partsPrimaryCategory
);

const selectPartsSecondaryCategory = createSelector(
  selectPCBuilder,
  (state) => state.partsSecondaryCategory
);

const selectCompatibilitySwitch = (state) => {
  const partsPrimaryCategory = selectPCBuilder(state).partsPrimaryCategory;
  // const partsPrimaryCategory = selectPartsPrimaryCategory(state);
  switch (partsPrimaryCategory) {
    case CPU:
    case Motherboard:
    case RAM:
      return 1;
    default:
      return 0;
  }
  // const selectedProductId = _.get(state.PCBuilder, "selectedProductId", null);
  // return selectedProductId === '999' ? 0 : 1;
};

const selectSelectedProductId = createSelector(
  selectPCBuilder,
  (state) => state.selectedProductId
);

const selectPartsCompatibleLoading = createSelector(
  selectPCBuilder,
  (state) => state.partsCompatibleStatus === SliceStatusValue.loading
);

const selectPartsCompatibleSuccess = createSelector(
  selectPCBuilder,
  (state) => state.partsCompatibleStatus === SliceStatusValue.resolve
);

const selectPartsCompatibleError = createSelector(
  selectPCBuilder,
  (state) => state.partsCompatibleStatus === SliceStatusValue.reject
);

const selectPartsCompatible = (state) => {
  const partsCompatible = selectPCBuilder(state).partsCompatible || [];
  const filteredPartsCompatible = partsCompatible?.filter((x) => x?.product_id);
  return filteredPartsCompatible;
};

const selectBuildParts = createSelector(
  selectPCBuilder,
  (state) => state.buildParts
);

const selectIsEmptyBuildParts = createSelector(selectPCBuilder, (state) =>
  _.isEmpty(state.buildParts)
);

const selectIsAddComponentDisabledFactory = (componentName: ComponentName) => {
  return (state) => {
    const buildParts = selectBuildParts(state);
    const hasCpu = _.some(buildParts, ["category_name", CPU]);
    const hasMotherboard = _.some(buildParts, ["category_name", Motherboard]);
    const isAddComponentDisabled =
      (!hasCpu && componentName === MOTHERBOARD) ||
      (!hasMotherboard && componentName === MEMORY);
    return isAddComponentDisabled;
  };
};

const selectBuildPartFactory = (componentName: ComponentName) => {
  return (state) => {
    const buildParts = selectBuildParts(state);
    const categoryName = mapComponentNameToCategoryName(componentName);
    const buildPart = _.find(buildParts, ["category_name", categoryName]);
    return buildPart;
  };
};

const selectBuildPartsTotalAmount = (state) => {
  const buildParts = selectBuildParts(state);

  const grandTotal = buildParts.reduce((total, part) => {
    total += getTotalViaQtyAndRetailPrice(part?.retail_price, part?.quantity);
    if (part.multipleParts) {
      total += part.multipleParts.reduce((subTotal, mp) => {
        const { quantity, retail_price } = mp;
        subTotal += getTotalViaQtyAndRetailPrice(retail_price, quantity);
        return subTotal;
      }, 0);
    }
    return total;
  }, 0);

  return grandTotal;
};

const selectBuildPartsToCart = (state) => {
  const buildParts = selectBuildParts(state);
  const cart = [];

  buildParts.forEach((part) => {
    if (part.multipleParts) {
      part.multipleParts.forEach((multiPart) => {
        const newItem = {
          product_slug: multiPart?.product_id,
          product_name: multiPart?.product_name,
          img_thumbnail: multiPart?.image_url,
          amount: multiPart?.retail_price,
          quantity: multiPart?.quantity,
          with_bundle: multiPart?.with_bundle,
          category_id: multiPart?.category_id,
          category: multiPart?.category_name,
          compatible_mobos: multiPart?.compatible_mobos || [],
          stocks_left: multiPart?.stocks_left || 100,
        };
        cart.push(newItem);
      });
    } else {
      const newItem = {
        product_slug: part?.product_id,
        product_name: part?.product_name,
        img_thumbnail: part?.image_url,
        amount: part?.retail_price,
        quantity: part?.quantity,
        with_bundle: part?.with_bundle,
        category_id: part?.category_id,
        category: part?.category_name,
        compatible_mobos: part?.compatible_mobos || [],
        stocks_left: part?.stocks_left || 100,
      };
      cart.push(newItem);
    }
  });
  return cart;
};

const selectSearchTitle = (state) => {
  const partsPrimaryCategory = selectPartsPrimaryCategory(state);
  const partsSecondaryCategory = selectPartsSecondaryCategory(state);

  if (partsSecondaryCategory === RAM) {
    return "CHOOSE A MEMORY MODULE";
  }

  const categoryName = partsSecondaryCategory || partsPrimaryCategory;
  const componentName = mapCategoryNameToComponentName(categoryName);

  return `CHOOSE A ${componentName}`;
};

export const selectors = {
  selectPartsPrimaryCategory,
  selectPartsSecondaryCategory,
  selectCompatibilitySwitch,
  selectSelectedProductId,
  selectPartsCompatibleLoading,
  selectPartsCompatibleSuccess,
  selectPartsCompatible,
  selectPartsCompatibleError,
  selectIsEmptyBuildParts,
  selectIsAddComponentDisabledFactory,
  selectBuildParts,
  selectBuildPartFactory,
  selectBuildPartsTotalAmount,
  selectBuildPartsToCart,
  selectSearchTitle,
};
