export const navigationRoutes = {
  homepage: "/",
  checkout: "/checkout",
  aiBuilder: "/ai-builder",
  worthItBuilder: "/pcbuilder",
  buildGallery: "/builds-gallery",
  pricelist: "/pricelist",
  pcPackage: "/pc-package",
  reviews: "/reviews",
  googleReviews: "/google-reviews",
  diyBuilder: "/diy-builder",
  poweredByAsus: "/powered-by-asus",

  //===== when login
  pcwProfile: "/profile",
  pcwOrder: "/orders",
  pcwAccountSecurity: "/account-security",
  userCart: "/user-cart",
  productWarranty: "/product-warranty",
  address: "/address",
  accountSecurity: "/account-security",
  myBuilds: "/my-builds",
  blogs: "/blogs",

  // Resources
  termsAndConditions: "/terms-and-conditions",
  howToOrder: "/how-to-order",
  frequentlyAskedQuestions: "/frequently-ask-question",
  privacyPolicy: "/privacy-policy",

  // Contact
  contactUs: "/contact-us",
};
