import Script from "next/script";
import React from "react";

const LiveChatScript: React.FC = () => {
  return (
    <div>
      <Script id="my-alice-live-chat">
        {`!function(){var e=document.createElement("div");e.id="myAliceWebChat";var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://livechat.myalice.ai/index.js";var a=document.body.getElementsByTagName("script");(a=a[a.length-1]).parentNode.insertBefore(t,a),a.parentNode.insertBefore(e,a),t.addEventListener("load",function(){MyAliceWebChat.init({selector:"#myAliceWebChat",platformId:'17714',primaryId:'85cae134b8eb11eea7b9de854d135f6e',token:'2248f6607f11009040b340c78433314d68544a15d769d394' })})}();`}
      </Script>
    </div>
  );
};

export default LiveChatScript;
