import Script from "next/script";
import { isEnvProduction } from "common/constants/feature-toggles";
import { useEffect } from "react";
import { useRouter } from "next/router";
import {
  customerInfoPixel,
  getPixelIdViaAddress,
} from "modules/meta-pixel/util";
import {
  initiateCheckout,
  purchase,
  addToCart,
  metaPixelEarnshaw,
  metaPixelAlabang,
  metaPixelLaptop,
} from "modules/meta-pixel";

export const pageView = () => {
  if (
    typeof window !== "undefined" &&
    typeof (window as any).fbq === "function"
  ) {
    (window as any).fbq("track", "PageView");
  }
};

// Dont use this for tracking.. USE fbqTrack
// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const fbq = (trackStr = "track", name, options = {}) => {
  if (
    typeof window !== "undefined" &&
    typeof (window as any).fbq === "function"
  ) {
    (window as any).fbq(trackStr, name, options);
  }
};

// Dont use this for tracking.. USE fbqTrack
// https://developers.facebook.com/docs/facebook-pixel/advanced/
// This is only for single pixel tracking and ONLY ALLOWED FOR STANDARD FB EVENTS
export const fbqSingle = (pixelId: string, name: string, options = {}) => {
  if (
    typeof window !== "undefined" &&
    typeof (window as any).fbq === "function"
  ) {
    (window as any).fbq("trackSingle", pixelId, name, options);
  }
};

export const fbqTrackSingle = (pixelId, name, options = {}) => {
  if (typeof window !== "undefined" && typeof window.fbq === "function") {
    const pixelEvents = [initiateCheckout, purchase, addToCart];

    if (pixelEvents.includes(name)) {
      const alreadyHasASession = sessionStorage?.getItem(name);

      if (alreadyHasASession) {
        // Do not record pixel if session already exists
        return;
      }

      // set session data
      sessionStorage?.setItem(name, "true");
    }

    const customerInfo = customerInfoPixel();
    fbqSingle(pixelId, name, { ...options, ...customerInfo });
  }
};

export const fbqTrack = (name, options = {}) => {
  if (typeof window !== "undefined" && typeof window.fbq === "function") {
    const pixelEvents = [initiateCheckout, purchase, addToCart];

    if (pixelEvents.includes(name)) {
      const alreadyHasASession = sessionStorage?.getItem(name);

      if (alreadyHasASession) {
        // Do not record pixel if session already exists
        return;
      }

      // set session data
      sessionStorage?.setItem(name, "true");
    }

    const customerInfo = customerInfoPixel();
    window.fbq("track", name, { ...options, ...customerInfo });
  }
};

export const fbqTrackSingleViaAddress = (
  name: "Purchase" | "InitiateCheckout",
  address: string,
  options = {}
) => {
  if (typeof window !== "undefined" && typeof window.fbq === "function") {
    const pixelId = getPixelIdViaAddress(address);

    if (pixelId) {
      const customerInfo = customerInfoPixel();
      fbqSingle(pixelId, name, { ...options, ...customerInfo });

      if (pixelId !== metaPixelEarnshaw) {
        fbqSingle(metaPixelEarnshaw, name, { ...options, ...customerInfo });
      }
    }
  }
};

export const fbqTrackPurchase = (address: string, options = {}) =>
  fbqTrackSingleViaAddress(purchase, address, options);

export const fbqTrackInitiateCheckout = (address: string, options = {}) =>
  fbqTrackSingleViaAddress(initiateCheckout, address, options);

const FacebookMetaPixel = () => {
  const router = useRouter();

  useEffect(() => {
    if (!isEnvProduction) {
      return;
    }

    pageView();

    const handleRouteChange = () => {
      pageView();
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  if (!isEnvProduction) {
    return null;
  }

  return (
    <>
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${metaPixelEarnshaw});
            fbq('init', ${metaPixelAlabang});
            fbq('init', ${metaPixelLaptop});
          `,
        }}
      />
      <noscript>
        {/* eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text */}
        <img
          width="1"
          height="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=1212485753045487&ev=PageView&noscript=1"
        />
      </noscript>
    </>
  );
};

export default FacebookMetaPixel;
