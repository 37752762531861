import { useCallback, useMemo, useState } from "react";

import modules from "modules";
import { useAppSelector } from "./use-app-selector";
import { useAppDispatch } from "./use-app-dispatch";
import { useRouter } from "next/router";
import { ProductsPayload } from "modules/products/types";
import { categoryNameToId } from "utils/categoryConverterUtils";
import { ProdSortDirPayloadEnum } from "common";
import { fbqTrack } from "utils/FacebookMetaPixel";
import { MainCategoryDetails } from "modules/category/types";

const { selectors: productsSelectors, actions: productsActions } =
  modules.products;

const { selectors: categorySelectors } = modules.category;

type SearchV2Params = MainCategoryDetails & { page?: number };

export const useSearchPage = () => {
  const router = useRouter();
  const keyword: string = (router?.query?.id as string) || ("" as string);
  const queries = router?.query as ProductsPayload | undefined;

  const mainCategoryItem = useAppSelector(
    categorySelectors.selectMainCategoryItem
  );

  const [selectedSearchPageCategory, setSearchSelectedPageCategory] =
    useState(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const categoryName = useMemo(
    () => (mainCategoryItem ? mainCategoryItem.name : ""),
    []
  );
  const dispatch = useAppDispatch();

  /** ========== SELECTORS / VARIABLES / CONSTANTS ========== */
  const searchPageItems = useAppSelector(
    productsSelectors.selectSearchProductsItems
  );
  const isSearchPageLoading = useAppSelector(
    productsSelectors.selectSearchProductsLoading
  );
  const searchPageResponse = useAppSelector(
    productsSelectors.selectSearchProductsResponse
  );

  const productSearchFacet = useAppSelector(
    productsSelectors.selectProductSearchingFacet
  );

  const responseMeta = useMemo(() => {
    return searchPageResponse?.meta;
  }, [searchPageResponse]);

  const handleSelectSearchPageCategory = (categories) => {
    const selectedCategory = categories?.find(
      (category) =>
        category?.id ===
        categoryNameToId(queries?.category?.toString(), categories)
    );

    setSearchSelectedPageCategory(
      selectedCategory?.name ? selectedCategory?.name : categoryName
    );
  };

  /** ========== FUNCTIONS ========== */
  const searchPageRequest = useCallback(
    (categoryID?: number) => {
      fbqTrack("Search", {
        search_string: keyword,
        content_category: "Product Search Page",
      });

      const newSort =
        queries?.sort_direction === ProdSortDirPayloadEnum.Descending
          ? "desc"
          : "asc";

      const payload = {
        ...queries,
        keyword,
        category: categoryID,
        sort_direction: newSort,
        limit: 48,
      };
      delete payload.id;
      dispatch(productsActions.getSearchPageProductsThunk(payload));
    },
    [dispatch, keyword, queries]
  );

  const searchPageRequestV2 = useCallback(
    (searchParams?: SearchV2Params, categories?: any) => {
      fbqTrack("Search", {
        search_string: keyword,
        content_category: "Product Search Page",
      });

      const newSort =
        queries?.sort_direction === ProdSortDirPayloadEnum.Descending
          ? "desc"
          : "asc";

      const manufacturerID = categoryNameToId(
        queries?.manufacturer?.toString(),
        searchParams?.target_data
      );

      const categoryID = categoryNameToId(
        queries?.category?.toString(),
        categories
      );

      const payload = {
        ...queries,
        sub_category_ids: searchParams?.sub_category_ids,
        manufacturer: manufacturerID,
        category: categoryID,
        keyword,
        sort_direction: newSort,
        limit: 48,
      };

      delete payload.id;
      dispatch(productsActions.getSearchPageProductsThunk(payload));
    },
    [dispatch, keyword, queries]
  );

  return {
    isSearchPageLoading,
    searchPageItems,
    searchPageResponse,
    responseMeta,
    keyword,
    queries,
    selectedSearchPageCategory,
    categoryName,
    productSearchFacet,
    handleSelectSearchPageCategory,
    searchPageRequest,
    searchPageRequestV2,
  };
};
